.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.bg-black {
    background: black;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.title {
    color: #3C4858;
    margin-top: 30px;
    min-height: 32px;
    font-family: "Roboto Slab", "Times New Roman", serif;
    font-weight: 700;
    text-decoration: none;
}
